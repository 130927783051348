import React from 'react'
import Header from '../components/global/header';
import Web_faq_sec from '../components/sections/web_faq/web_faq_sec';
import Footer from '../components/global/footer';

import Transition from '../components/transition';
import { gsap } from "gsap";
import { useEffect , useRef } from 'react';
import { Power3, Power4 } from "gsap";



export default function Faq_page() {

    let body = useRef(null);
    var tl_1 = gsap.timeline()
  
    useEffect(() => {
  
      tl_1.to(body,{
        opacity: "1",
        pointerEvents: "auto",
        ease: Power4.easeInOut,
        delay: 1.2
      });
      return () => {
        tl_1.to(body, {
          opacity: "0",
          pointerEvents: 'none'
        });
      }
  
    })


  return (
    <>
        <Transition/>
        <div className='home_page'>
          <div ref={(el) => (body = el)} className="Headd">
                <Header/>
                <Web_faq_sec/>
                <Footer/>
            </div>
        </div>
    </>
  )
}
