import React from 'react'
import Header from '../components/global/header';
import Blog_category_spotlight from '../components/spotlight/blog_category_spotlight';
import Cta from '../components/sections/cta';
import Footer from '../components/global/footer';
import '../assets/css/search.css';
import { useParams } from "react-router-dom";
import useFetch from "react-fetch-hook";
import Masonry from 'react-masonry-css';
import $ from "jquery";

import Transition from '../components/transition';
import { gsap } from "gsap";
import { useEffect , useRef } from 'react';
import { Power3, Power4 } from "gsap";
import Helmet from 'react-helmet';
import Blog_category_meta from '../components/sections/blog/blog_category_meta';

export default function Blog_category_page() {

    // gsap.registerPlugin(ScrollTrigger);


    const categoryMap = {
        technology: 51,
        policy: 53,
        esg: 54,
        'supply-chain-warehousing': 52
    };

    let body = useRef(null);
    var tl_1 = gsap.timeline()
    
    useEffect(() => {
  
     
  
      tl_1.to(body,{
        opacity: "1",
        pointerEvents: "auto",
        ease: Power4.easeInOut,
        delay: 1.2
      });
      return () => {
        tl_1.to(body, {
          opacity: "0",
          pointerEvents: 'none'
        });
      }
  
    })


    let { slug } = useParams();
    const categoryId = categoryMap[slug];

    const { isLoading, data, error } = useFetch(
      `https://phpstack-725513-2688800.cloudwaysapps.com/cms/wp-json/wp/v2/posts?categories=${categoryId}&per_page=50`
    );

 
     const breakpointColumnsObj = {
       default:1,
       1100: 1,
       700: 2,
       500: 1
   };
 
   useEffect(() => {


    (function ($) {
      var pagify = {
          items: {},
          container: null,
          totalPages: 1,
          perPage: 3,
          currentPage: 0,
          createNavigation: function () {
              this.totalPages = Math.ceil(this.items.length / this.perPage);

              $('.pagination', this.container.parent()).remove();
              var pagination = $('<div class="pagination"></div>').append('<a class=" pagi nav prev disabled" data-next="false"> < Prev </a>');

              $(`<img  src={require('../../../assets/img/svg/date_icon.svg').default}></img>`).appendTo('.pagi.nav.prev');
              for (var i = 0; i < this.totalPages; i++) {
                  var pageElClass = "page";
                  if (!i)
                      pageElClass = "page current";
                  var pageEl = '<a class="' + pageElClass + '" data-page="' + (
                      i + 1) + '">' + (
                          i + 1) + "</a>";
                  pagination.append(pageEl);
              }
              pagination.append('<a class="pagi nav next" data-next="true"> Next > </a>');
              $('.blog_cate_row').append(pagination);
              // this.container.before(pagination);

              var that = this;
              $("body").off("click", ".nav");
              this.navigator = $("body").on("click", ".nav", function () {
                  var el = $(this);
                  that.navigate(el.data("next"));
              });

              $("body").off("click", ".page");
              this.pageNavigator = $("body").on("click", ".page", function () {
                  var el = $(this);
                  that.goToPage(el.data("page"));
              });
          },
          navigate: function (next) {
              // default perPage to 5
              if (isNaN(next) || next === undefined) {
                  next = true;
              }
              $(".pagination .nav").removeClass("disabled");
              if (next) {
                  this.currentPage++;
                  if (this.currentPage > (this.totalPages - 1))
                      this.currentPage = (this.totalPages - 1);
                  if (this.currentPage == (this.totalPages - 1))
                      $(".pagination .nav.next").addClass("disabled");
              }
              else {
                  this.currentPage--;
                  if (this.currentPage < 0)
                      this.currentPage = 0;
                  if (this.currentPage == 0)
                      $(".pagination .nav.prev").addClass("disabled");
              }

              this.showItems();
          },
          updateNavigation: function () {

              var pages = $(".pagination .page");
              pages.removeClass("current");
              $('.pagination .page[data-page="' + (
                  this.currentPage + 1) + '"]').addClass("current");
          },
          goToPage: function (page) {

              this.currentPage = page - 1;

              $(".pagination .nav").removeClass("disabled");
              if (this.currentPage == (this.totalPages - 1))
                  $(".pagination .nav.next").addClass("disabled");

              if (this.currentPage == 0)
                  $(".pagination .nav.prev").addClass("disabled");
              this.showItems();
          },
          showItems: function () {
              this.items.hide();
              var base = this.perPage * this.currentPage;
              this.items.slice(base, base + this.perPage).show();

              this.updateNavigation();
          },
          init: function (container, items, perPage) {
              this.container = container;
              this.currentPage = 0;
              this.totalPages = 1;
              this.perPage = perPage;
              this.items = items;
              this.createNavigation();
              this.showItems();
          }
      };

      // stuff it all into a jQuery method!
      $.fn.pagify = function (perPage, itemSelector) {
          var el = $(this);
          var items = $(itemSelector, el);

          // default perPage to 5
          if (isNaN(perPage) || perPage === undefined) {
              perPage = 3;
          }

          // don't fire if fewer items than perPage
          if (items.length <= perPage) {
              return true;
          }

          pagify.init(el, items, perPage);
      };
  })($);

  $(".blog_cate_row").pagify(6, ".category_blog_col");

      
 
    if ($('.my-masonry-grid_column .category_blog_col').is(':visible'))
       {
         $('.result-error').hide();
         $('.found-txt').show();
       }
       else {         
           $('.result-error').show(); 
           $('.found-txt').hide();              
       }
   })
 

   
  return (
    <> <Transition/>
            <div className='pg-layout'>
            <div ref={(el) => (body = el)} className="Headd">

              <Blog_category_meta cat_id={categoryId}/>
          <Header/>


            <Blog_category_spotlight/>

            <div className='blog_tag_categ_pg'>
                <div className='cstm_container'>
                    <div className='serch-result-dv blg-srch-rsult'>
                        <div className='row'>
                            <div className='col-12 srch_col'>
                            {/* <p className=' section_name  found-txt'> Category &#8220;<span>{id}</span>&#8221;</p>  */}
                                <Masonry
                                    breakpointCols={breakpointColumnsObj}
                                    className="my-masonry-grid"
                                    columnClassName="my-masonry-grid_column"
                                    >

                                        <div className='row no_LR_margin blog_cate_row'>
                                          {/* {data && data.map(({ id , acf , title , category , slug}) => (
                                              <div className='col-md-6 col-lg-4 col-12 blog_feat_col2 blog_bx' key={id} >
                                                 <a className='blog_anchor' href={'/blogs/'+ slug}>
                                                    <div className='feature_blog_bx'>
                                                        <div className='fbb1'>
                                                            <img className='blog_bg_img' src={acf.blog_image}></img>
                                                            <p className='bl_bx_ttl'>{category}</p>
                                                        </div>
                                                        <div className='fbb2'>
                                                            <h3 className='sub_para'  dangerouslySetInnerHTML={{ __html: title }}></h3>
                                                            <p className='bl_bx_date'>{acf.blog_publish_date}</p>
                                                            <p className='para blg_bx_para'>Did you come here for something in particular or just general Riker-bashing? And blowing.</p>
                                                        </div>
                                                    </div>
                                                  </a>  
                                              </div>
                                          ))} */}
                                          {data && data.map(({ id, acf, title, category, slug, content }) => (    
                                            <div className='col-md-6 col-lg-4 col-12  category_blog_col' key={id}>
                                                <a href={'/blog/'+ slug}>
                                                    <div className='image-conatoner'>
                                                        <div className='img-box'>
                                                            <img src={acf.featured_slider_image} alt={acf.blog_image_alt_text}></img>
                                                        </div>
                                                        <div className='text-box'>
                                                            <p className='new-para' dangerouslySetInnerHTML={{ __html: category }}></p>
                                                           
                                                            <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
                                                            <div className='icon-blog'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" fill="none">
                                                                    <path d="M2.75514 7.2H4.13271V8.8H2.75514V7.2ZM12.3981 3.2V14.4C12.3981 15.28 11.7782 16 11.0206 16H1.37757C1.01222 16 0.661825 15.8314 0.403481 15.5314C0.145136 15.2313 0 14.8243 0 14.4L0.00688784 3.2C0.00688784 2.32 0.613019 1.6 1.37757 1.6H2.06635V0H3.44392V1.6H8.9542V0H10.3318V1.6H11.0206C11.7782 1.6 12.3981 2.32 12.3981 3.2ZM1.37757 4.8H11.0206V3.2H1.37757V4.8ZM11.0206 14.4V6.4H1.37757V14.4H11.0206ZM8.26542 8.8H9.64299V7.2H8.26542V8.8ZM5.51028 8.8H6.88785V7.2H5.51028V8.8Z" fill="white" />
                                                                </svg>
                                                                <p>{acf.blog_publish_date}</p>
                                                            </div>
                                                            <a class="blog_read_more_btn" href={'/blog/'+ slug}>Read More</a>
                                                            {/* <p className='blg_bx_para' dangerouslySetInnerHTML={{ __html: content }}></p> */}

                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                          ))} 
                                        </div>
           
                                </Masonry>
                                {/* : 'not found'} */}
                            </div>
                        
                        </div>
                        <div className='result-error'>
                          <h2>No Data In This Category</h2>  
                        </div>

                    </div>
                </div>
            </div>
          <Footer/>
        

      </div>
      </div>
    </>
  )
}
