import React from 'react'
import Helmet from 'react-helmet';
import useFetch from "react-fetch-hook";


export default function Blog_category_meta(props) {

    const { isLoading, data, error } = useFetch(
        `https://phpstack-725513-2688800.cloudwaysapps.com/cms/wp-json/wp/v2/blog-category-meta?categories=${props.cat_id}`
    );

  return (
    <>
       {data && data.map(({ id , acf , title , category , slug}) => (
            <Helmet>
                    <title>{acf.meta_title}</title>
                    <meta name="description" content={acf.meta_description} />
                    {/* <meta name="keyword" content={acf.meta_keyword} />

                    <meta property="og:title" content={acf.meta_title} />
                    <meta property="og:description" content={acf.meta_description} />
                    <meta property="og:image" content={acf.blog_image} />

                    <meta name="twitter:title" content={acf.meta_title} />
                    <meta name="twitter:description" content={acf.meta_description} />
                    <meta name="twitter:image" content={acf.blog_image} /> */}

            </Helmet>

        ))} 
    </>
  )
}
